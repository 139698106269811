<script setup lang="ts">
const props = defineProps(["img", "isHtml"]);
const url = props.img?.sourceUrl || props.img;

let svgHtml = "";
if (props.isHtml) {
  svgHtml = props.img;
} else {
  svgHtml = sessionStorage.getItem(`svimg_${url}`);
}

if (_IsEmpty(svgHtml) || svgHtml === "null") {
  const {data: svgHtmlInput} = await useFetch(url, {
    server: false,
    lazy: false,
    method: "GET",
    parseResponse(responseText) {
      return responseText;
    },
  });
  sessionStorage.setItem(`svimg_${url}`, svgHtmlInput.value);
  svgHtml = svgHtmlInput.value;
}

function parseStyles(styleTag) {
  if (!styleTag) {
    return {};
  }

  const classCollection = {};
  const plain = styleTag.innerHTML;
  const regex = /\.([^\s{]+)[\s]*\{([\s\S]+?)\}/;
  const propertyRegex = /([\w\-]+)[\s]*:[\s]*([^;]+)/;
  const result = plain.match(new RegExp(regex, "g"));
  if (result) {
    result.forEach((c) => {
      const classResult = c.match(regex);
      const propertiesResult = classResult[2].match(new RegExp(propertyRegex, "g"));
      const properties = propertiesResult.reduce((collection, item) => {
        const p = item.match(propertyRegex);
        collection[p[1]] = p[2];
        return collection;
      }, {});

      classCollection[classResult[1]] = properties;
    });
    return classCollection;
  }

  return {};
}

function applyProperties(element, properties) {
  if (!properties) {
    return;
  }

  Object.keys(properties).forEach((key) => {
    element.style[key] = properties[key];
  });
}

function applyStyles(element, styles) {
  const classNames = (element.getAttribute("class") || "").split(" ");
  classNames.forEach((c) => {
    applyProperties(element, styles[c]);
  });
  element.setAttribute("class", "");
}

onMounted(() => {
  nextTick(() => {
    if (svgHtml) {
      //const svgElement = new DOMParser().parseFromString(svgHtml.value, "image/svg+xml");
      var svgCollection = document.querySelectorAll(".svg-icon svg");

      for (let i = 0; i < svgCollection.length; i += 1) {
        const svg = svgCollection[i];
        const styles = parseStyles(svg.querySelector("style"));
        const elements = svg.querySelectorAll("[class]");
        for (let j = 0; j < elements.length; j += 1) {
          applyStyles(elements[j], styles);
        }
      }
    }
  });
});
onUpdated(() => {
  if (svgHtml) {
    //const svgElement = new DOMParser().parseFromString(svgHtml.value, "image/svg+xml");
    var svgCollection = document.querySelectorAll(".svg-icon svg");

    for (let i = 0; i < svgCollection.length; i += 1) {
      const svg = svgCollection[i];
      const styles = parseStyles(svg.querySelector("style"));
      const elements = svg.querySelectorAll("[class]");
      for (let j = 0; j < elements.length; j += 1) {
        applyStyles(elements[j], styles);
      }
    }
  }
});
</script>

<template>
  <span :key="svgHtml" class="svg-icon" v-html="svgHtml"></span>
</template>
